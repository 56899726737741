import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import FeatureItem from "./featureItem";

const Features = () => {
  const data = useStaticQuery(graphql`
    {
      allFeaturesJson {
        nodes {
          id
          title
          description
          icon {
            publicURL
          }
        }
      }
    }
  `);
  return (
    <div id="features">
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 md:py-24 py-12">
          <div className="grid xl:grid-cols-12 grid-cols-1 gap-8 text-center">
            <div className="xl:col-span-2 xl:block hidden"></div>
            <div className="xl:col-span-8 flex flex-col gap-12 justify-center">
              <div className="flex flex-col gap-2 text-center">
                <h3 className="lg:text-display-xl md:text-display-lg text-display-md font-semibold">
                  Elektroniczny dziennik korespondencji
                </h3>
                <p className="md:text-body-lg text-body-md font-normal text-neutral-700">
                  Zamień papierową księgę lub arkusz kalkulacyjny na PaperHub
                </p>
              </div>
            </div>
            <div className="xl:col-span-2 xl:block hidden"></div>
          </div>
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 gap-0 md:pt-10 md:pb-6 items-center">
            {data.allFeaturesJson.nodes.map((node) => (
              <FeatureItem
                icon={node.icon.publicURL}
                title={node.title}
                description={node.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Features;
